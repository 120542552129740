<template>
  <div class="">
   
    <div class="d-flex text-align-section">
    
   
      <label title="Bold" for="bold" @click="internalTextBold=internalTextBold=='bold'?'':'bold'" :class="[internalTextBold=='bold'?'label-active':'']" class="editor-text-bold-control">
        <svg
          width="13.33px"
          height="13.33px"
          fill="currentColor"
          viewBox="0 0 1920 1920"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_iconCarrier">
            <path
              d="M480.286 822.857h548.571c151.269 0 274.286-123.017 274.286-274.286 0-151.268-123.017-274.285-274.286-274.285H480.286v548.571Zm0 822.857H1166c151.269 0 274.286-123.017 274.286-274.285 0-151.269-123.017-274.286-274.286-274.286H480.286v548.571ZM1166 1920H206V0h822.857c302.537 0 548.572 246.034 548.572 548.571 0 134.263-48.549 257.418-128.778 352.732 159.223 96.137 265.92 270.994 265.92 470.126 0 302.537-246.034 548.571-548.571 548.571Z"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      </label>
      <label title="Italic" for="italic" @click="internalTextItalic=internalTextItalic=='italic'?'':'italic'" :class="[internalTextItalic=='italic'?'label-active':'']"  class="editor-text-italic-control">
        <svg
          width="13.33px"
          height="13.33px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_iconCarrier">
            <path
              d="M10 3H20M4 21H14M15 3L9 21"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      </label>
      <label for="underline" @click="textUnderLine=textUnderLine=='underline'?'none':'underline'" title="Underline"  :class="[internalTextUnderLine=='underline'?'label-active':'']" class="editor-text-underline-control">
        <svg
          width="13.33px"
          height="13.33px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="currentColor"
        >
          <g id="SVGRepo_iconCarrier">
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Dribbble-Light-Preview"
                transform="translate(-420.000000, -5199.000000)"
                fill="currentColor"
              >
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  <path
                    d="M364,5059 L384,5059 L384,5057 L364,5057 L364,5059 Z M366,5046 L366,5039 L368,5039 L368,5046 C368,5055.333 380,5055.333 380,5046 L380,5039 L382,5039 L382,5046 C382,5058 366,5058 366,5046 L366,5046 Z"
                    id="underline-[#669]"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </label>
      <label for="line-through" title="Line through" @click="textUnderLine=textUnderLine=='line-through'?'none':'line-through'" :class="[internalTextUnderLine=='line-through'?'label-active':'']"  class="editor-text-line-through-control">
        <svg
          fill="currentColor"
          width="20.33px"
          height="20.33px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_iconCarrier">
            <rect x="5.77" y="12.17" width="12.46" height="1.09"></rect>
            <path
              d="M16.7,13.65H12.89c.59.34,1,.6,1.18.74a3.39,3.39,0,0,1,1,1.07,2.38,2.38,0,0,1,.31,1.14,2.3,2.3,0,0,1-.82,1.76,3.18,3.18,0,0,1-2.22.74,4.7,4.7,0,0,1-2.23-.54A3.77,3.77,0,0,1,8.56,17.2a7.41,7.41,0,0,1-.79-2.46H7.36V20h.41a1.35,1.35,0,0,1,.24-.7.59.59,0,0,1,.44-.17,6.5,6.5,0,0,1,1.39.35,12.63,12.63,0,0,0,1.45.41,7.26,7.26,0,0,0,1.25.1A4.87,4.87,0,0,0,16,18.72a4,4,0,0,0,1.34-3A3.8,3.8,0,0,0,16.92,14C16.86,13.87,16.78,13.77,16.7,13.65Z"
            ></path>
            <path
              d="M8.89,11.21c.21.17.49.36.81.57h4.87c-.48-.29-1.07-.62-1.76-1A12.49,12.49,0,0,1,9.48,8.54,1.93,1.93,0,0,1,9,7.26a2.18,2.18,0,0,1,.77-1.63,2.72,2.72,0,0,1,1.93-.71,4.14,4.14,0,0,1,2,.53,3.78,3.78,0,0,1,1.49,1.43,6.6,6.6,0,0,1,.73,2.42h.41V4h-.41a1.39,1.39,0,0,1-.3.7.67.67,0,0,1-.48.17,2.64,2.64,0,0,1-.89-.28A6.45,6.45,0,0,0,11.68,4,4.49,4.49,0,0,0,8.47,5.21,3.75,3.75,0,0,0,7.21,8a3.57,3.57,0,0,0,.43,1.73A4.72,4.72,0,0,0,8.89,11.21Z"
            ></path>
          </g>
        </svg>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    textBold: {
        type: String,
        default: 'normal'
      },
      textItalic: {
        type: String,
        default: 'normal'
      },
      textUnderLine: {
        type: String,
        default: 'normal'
      },
     
  },
  data() {
      return {
        internalTextBold: this.textBold,
        internalTextItalic: this.textItalic,
        internalTextUnderLine: this.textUnderLine,
      };
    },

    watch: {
    textBold(newValue) {
      this.internalTextBold = newValue;
    },
    textItalic(newValue) {
      this.internalTextItalic = newValue;
    },
    textUnderLine(newValue) {
      this.internalTextUnderLine = newValue;
    },
    internalTextBold(newBoldValue) {
      this.$emit('update:textBold', newBoldValue);
    },
    internalTextItalic(newItalicValue) {
      this.$emit('update:textItalic', newItalicValue);
    },
    internalTextUnderLine(newUnderLineValue) {
      this.$emit('update:textUnderLine', newUnderLineValue);
    }
  }
};
</script>

<style>
.text-align-section{
    color: #B3AFB6;
    background: #F7F7F7;
    width: fit-content;
    border-radius: 6px;

}
.text-align-section label{
 padding: 6px 12px;
 border: 1px solid transparent;
 margin: 0px;
 cursor:pointer;
}
.text-align-section label:hover{
    color: #000;
}
.label-active {
    border-radius: 6px!important;
border: 1px solid #D2D8E0!important;
background: #FFF!important;
color: black!important;
        }
</style>
