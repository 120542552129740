<template>
    <div>
      <label for="font-family" class="editor-label">{{ label }}</label>
      <div class="d-flex align-items-center" style="gap:4px">
          <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4167 3.33331L16.3834 6.95831L15.5834 7.17498C15.2084 6.44998 14.825 5.72498 14.3834 5.35831C13.9417 4.99998 13.425 4.99998 12.9167 4.99998H10.8334V13.75C10.8334 14.1666 10.8334 14.5833 11.1084 14.7916C11.3917 15 11.9417 15 12.5 15V15.8333H7.50003V15C8.05837 15 8.60837 15 8.8917 14.7916C9.1667 14.5833 9.1667 14.1666 9.1667 13.75V4.99998H7.08337C6.57503 4.99998 6.05837 4.99998 5.6167 5.35831C5.17503 5.72498 4.7917 6.44998 4.4167 7.17498L3.6167 6.95831L4.58337 3.33331H15.4167Z" fill="#B3AFB6"/>
              </svg>
          </div>
          
          <div class="w-100 editor-input-control position-relative d-flex align-items-center">
             
             
              <select 
                v-model="internalValue" 
                name="font-family" 
                ref="fontFamilySelect" 
                id="font-family" 
                class=" w-100 m-0 position-relative">
                  <option value="" disabled>Select Font</option>
                  <option v-for="font in getFontFamilyList" :key="font.value" :value="font.value">
                    {{ font.value }}
                  </option>
              </select>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import fontFamilyList from "../../../staticPages/fontFamilyList.js";
  
  export default {
    props: {
      value: String,
      label:{
        type:String,
        default:'Text style:'
      },    },
    computed: {
      getFontFamilyList() {
        return fontFamilyList;
      }
    },
    data() {
      return {
        internalValue: this.value,
      };
    },
    watch: {
      value(val) {
        this.internalValue = val;
      },
      internalValue(val) {
        this.$emit('input', val); 
      }
    }
  };
  </script>
  
  <style scoped>
  .font-family-chevron{
      position: absolute;
      right: 8px;
      z-index: 1;
  }
  .editor-input-control select{
    border:none;
    outline: none;
  }
  .editor-input-control:focus-within{
    box-shadow: 0px 0px 1px 1px var(--secondary-color);
  }
  </style>
  