<template>
  <div class="">
    <label for="button-text" class="editor-label">Text Alignment:</label>
    <div class="d-flex text-align-section">
     
      <label title="Left" for="left" @click="internalTextAlign='left'" :class="internalTextAlign=='left'?'active-editor-align':''" class="editor-text-left-control">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_26_2371)">
            <path
              d="M12.2223 12.2222H3.33337V13.7037H12.2223V12.2222ZM12.2223 6.29631H3.33337V7.77779H12.2223V6.29631ZM3.33337 10.7408H16.6667V9.25927H3.33337V10.7408ZM3.33337 16.6667H16.6667V15.1852H3.33337V16.6667ZM3.33337 3.33334V4.81482H16.6667V3.33334H3.33337Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_26_2371">
              <rect width="20" height="20" rx="2" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </label>
      <label title="Center" for="center" @click="internalTextAlign='center'" :class="internalTextAlign=='center'?'active-editor-align':''" class="editor-text-center-control">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_26_2373)">
            <path
              d="M6.29634 12.2222V13.7037H13.7037V12.2222H6.29634ZM3.33337 16.6667H16.6667V15.1852H3.33337V16.6667ZM3.33337 10.7408H16.6667V9.25927H3.33337V10.7408ZM6.29634 6.29631V7.77779H13.7037V6.29631H6.29634ZM3.33337 3.33334V4.81482H16.6667V3.33334H3.33337Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_26_2373">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </label>
      <label for="right" title="Right" @click="internalTextAlign='right'" :class="internalTextAlign=='right'?'active-editor-align':''" class="editor-text-right-control">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_26_2375)">
            <path
              d="M4.16663 15.8333H15.8333V14.537H4.16663V15.8333ZM8.05551 13.2407H15.8333V11.9444H8.05551V13.2407ZM4.16663 10.6481H15.8333V9.35184H4.16663V10.6481ZM8.05551 8.05555H15.8333V6.75925H8.05551V8.05555ZM4.16663 4.16666V5.46295H15.8333V4.16666H4.16663Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_26_2375">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </label>
      <label for="justify" title="Justify" @click="internalTextAlign='justify'" :class="internalTextAlign=='justify'?'active-editor-align':''" class="editor-text-justify-control">
        <i class="fa-solid fa-align-justify" style="font-size: 13px;"></i>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'left' // Default value for text alignment
    }
  },
  data() {
    return {
      internalTextAlign: this.value // Internal value for v-model binding
    };
  },
  watch: {
    internalTextAlign(newVal) {
      this.$emit('input', newVal); // Emit updated value to parent
    },
    value(newVal) {
      this.internalTextAlign = newVal; // Watch for changes in prop value
    }
  }
};
</script>

<style >
.text-align-section {
  color: #b3afb6;
  background: #f7f7f7;
  width: fit-content;
  border-radius: 6px;
}
.text-align-section label {
  padding: 6px 12px;
  border: 1px solid transparent;
  margin: 0px;
}
.text-align-section label:hover {
  color: #000;
}
.active-editor-align {
  border-radius: 6px;
  border: 1px solid #d2d8e0!important;
  background: #fff;
  color: black;
}
</style>
